import React from 'react';

const Admin = () => {
  return (
    <div>   
  <h2>Admin Stuff</h2>
  <img src="/ron.jpg" alt="A cute cat" width="300" height="200"></img>
  <div class="tenor-gif-embed" data-postid="16022601" data-share-method="host" data-aspect-ratio="1" data-width="100%"><a href="https://tenor.com/view/cute-dragon-omocat-charmander-pokemon-gif-16022601">Cute Dragon Omocat GIF</a>from <a href="https://tenor.com/search/cute+dragon-gifs">Cute Dragon GIFs</a></div> <script type="text/javascript" async src="https://tenor.com/embed.js"></script>
    </div>
  );
};

export default Admin;